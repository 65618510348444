@import "variables";
.progress {
  background-color: #E5E5E5;
  border-radius: 3px;
  box-shadow: none;
  height: 4px;
  margin-bottom: 20px;
}
.progress-thin{
  height: 2px;
}

@mixin progress-color($color, $type){
  .progress-bar.bg-#{$type}{
    background-color: $color !important;
  }
}

@include progress-color($primary-color,'primary');
@include progress-color($info-color,'info');
@include progress-color($success-color,'success');
@include progress-color($warning-color,'warning');
@include progress-color($danger-color,'danger')
